export const AccountInitState = {
  level: "profile",
};

export const AccountReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
