import { LOCATE_INFO } from "../actions/Locate";
export const LocateInitState = {
  newStorageDetail: {},
  quantity: null,
  productBarcode: null,
  cellBarcode: null,
};

export const LocateReducer = (state, action) => {
  switch (action.type) {
    case LOCATE_INFO:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
