export const ThemeInitState = {
  mode: "light",
  lang: localStorage.getItem("language"),
};

export const ThemeModeReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, mode: state.mode === "light" ? "dark" : "light" };
    case "CHANGE_LANG":
      localStorage.setItem("language", action.payload.lang);
      return { ...state, lang: action.payload.lang };

    default:
      return state;
  }
};
