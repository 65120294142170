import ReactLoading from "react-loading";
import "./Loading.css";
const Loading = ({ w, h, type, color, isFullHeigh = true }) => {
  return (
    <div
      className={`backColor d-flex justify-content-center align-items-center w-100 align-items-start ${
        isFullHeigh ? "vh-100" : "my-5  backUnset"
      }`}
    >
      <ReactLoading
        type={type || "spinningBubbles"}
        color={color || "#6b6b6b"}
        height={h}
        width={w}
      />
    </div>
  );
};

export default Loading;
