import { Route } from "react-router-dom";
import Loading from "./components/common/loading/Loading";
import DashboardLayout from "./components/layout/DashboardLayout";
import UseAuth from "./customHooks/UseAuth";
const PrivateRouteDashboard = ({ component: Component, ...rest }) => {
  const { isLogin, userAuthLoading } = UseAuth();

  if (userAuthLoading) {
    return <Loading w={150} h={150} />;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <DashboardLayout spacialMenu={true}>
            <Component {...props} />
          </DashboardLayout>
        ) : null
      }
    />
  );
};

export default PrivateRouteDashboard;
