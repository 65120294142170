import { USER_INFO, USER_LOGIN } from "../actions/User";

export const userInitState = {
  email: "",
  email_verified: false,
  isLogin: false,
  family_name: "",
  given_name: "",
  name: "",
  preferred_username: "",
  sub: "",
};

export const userReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return { ...action.payload, isLogin: true };

    case USER_INFO:
      return { ...action.payload, isLogin: true };

    default:
      return state;
  }
};
