import { ORDER_INFO } from "../actions/Order";
export const OrderInitState = {
  status: "allData",
};

export const OrderReducer = (state, action) => {
  switch (action.type) {
    case ORDER_INFO:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};
