import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./DashboardContainer.css";
const DashboardContainer = ({ children, showmenuList }) => {
  const loc = useLocation();
  const [conrainerClass, setConrainerClass] = useState("");
  useEffect(() => {
    if (loc.pathname === "/") {
      setConrainerClass("withPaddingJustLarge");
    } else {
      setConrainerClass("withPadding");
    }
  }, [loc.pathname, conrainerClass]);
  return (
    <div className="dashboard-container">
      <div className={`content-container ${conrainerClass}`}>{children}</div>
    </div>
  );
};

export default DashboardContainer;
