import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppWrapper } from "./context/Context";
import PrivateRoute from "./PrivateRoute";
import "./App.css";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "./components/errorBoundaryCom/ErrorBoundary";
import Loading from "./components/common/loading/Loading";
import PrivateRouteDashboard from "./PrivateRouteDashboard";
import Modules from "./components/account/accountSearch/accountSetting/Modules";

const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const IncomingComponet = lazy(() => import("./pages/incoming/IncomingMobile"));
const StockMobile = lazy(() => import("./pages/stock/StockMobile"));
const OrderMobile = lazy(() => import("./pages/orders/OrderMobile"));
const SetWizard = lazy(() => import("./pages/wizard/SetWizard"));
const Account = lazy(() => import("./pages/account/Account"));
const Logout = lazy(() => import("./pages/Logout"));

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime:3000000
      },
    },
  });
  var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)

  root.setAttribute("class", "dark");
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Router>
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>
                <Switch>
                  <PrivateRouteDashboard exact path="/" component={Dashboard} />
                  <PrivateRoute
                    exact
                    path="/Incoming"
                    component={IncomingComponet}
                  />
                  <PrivateRoute exact path="/Stock" component={StockMobile} />
                  <PrivateRoute path="/Orders" component={OrderMobile} />

                  <PrivateRoute exact path="/Account" component={Account} />

                  <PrivateRoute exact path="/Wizard" component={SetWizard} />
                  <PrivateRoute exact path="/Modules" component={Modules} />
                  <Route exact path="/LogOut" component={Logout} />
                </Switch>
                <ToastContainer
                  autoClose={3000}
                  newestOnTop
                  closeOnClick
                  pauseOnFocusLoss={false}
                  draggable
                  pauseOnHover
                  hideProgressBar={false}
                />
              </Suspense>
            </ErrorBoundary>
          </Router>
        </AppWrapper>
      </QueryClientProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
