import { ITEM_IN, HOW_MANY, STORAGE_PLACE } from "../actions/Step";

export const stepInitState = {
  itemSelectedName: null,
  itemSelectedBarcode: null,
  count: 1,
  corridor: 1,
  row: 3,
  cell: 23,
  deliveryCode: null,
  storageName: "",
  StorageId: 1,
  ProductId: 1,
  stock: 1,
  ProductImg: null,
  storagePlaceId: 1,
  cellNumber: "",
};
export const stepReducer = (state, action) => {
  switch (action.type) {
    case ITEM_IN:
      return { ...state, ...action.payload };

    case HOW_MANY:
      return { ...state, ...action.payload };
    case STORAGE_PLACE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
