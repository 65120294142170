import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
function FooterMobile() {
  const { t } = useTranslation();

  return (
    <div className="main-menu-mobile">
      <div className="bottom-mobile-menu">
        <div>
          <NavLink to={"/"}>
            <span className="home-outline"></span>
            <p>{t("Home")}</p>
          </NavLink>
        </div>
        <div>
          <NavLink exact activeClassName="menu-active-custom" to={"/Incoming"}>
            {/* <img alt="Incoming" src={incoming} /> */}
            <span className="incomming"></span>
            <p>{t("Incoming")}</p>
          </NavLink>
        </div>
        <div>
          <NavLink exact activeClassName="menu-active-custom" to={"/Stock"}>
            <span className="stock"></span>

            <p>{t("Stock")}</p>
          </NavLink>
        </div>

        <div>
          <NavLink exact activeClassName="menu-active-custom" to={"/Orders"}>
            <span className="order"></span>
            <p>{t("Order")}</p>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default FooterMobile;
