import { createContext, useContext, useReducer, useState } from "react";
import { userReducer, userInitState } from "./reducers/UserReducer";
import { stepReducer, stepInitState } from "./reducers/StepReducer";
import { OrderReducer, OrderInitState } from "./reducers/OrderReducer";
import { LocateReducer, LocateInitState } from "./reducers/RelocateReducer";
import { ThemeModeReducer, ThemeInitState } from "./reducers/ThemeModeReducer";
import { AccountInitState, AccountReducer } from "./reducers/AccountReducer";
const AppContext = createContext();

export const AppWrapper = ({ children }) => {
  const [user, modifyUser] = useReducer(userReducer, userInitState);
  const [step, setStep] = useReducer(stepReducer, stepInitState);
  const [status, setStatus] = useReducer(OrderReducer, OrderInitState);
  const [locate, setLocate] = useReducer(LocateReducer, LocateInitState);
  const [Theme, setTheme] = useReducer(ThemeModeReducer, ThemeInitState);
  const [Account, setAccount] = useReducer(AccountReducer, AccountInitState);
  const [productSelectCheckBoxArray, setProductSelectCheckBoxArray] = useState(
    []
  );
  return (
    <AppContext.Provider
      value={{
        Theme,
        setTheme,
        Account,
        setAccount,
        step,
        setStep,
        status,
        setStatus,
        locate,
        setLocate,
        user,
        modifyUser,
        productSelectCheckBoxArray,
        setProductSelectCheckBoxArray
      }}
    >
      <div>{children}</div>
    </AppContext.Provider>
  );
};

export const UseAppContext = () => {
  return useContext(AppContext);
};
