import React from "react";
import DashboardFooterMobile from "../global/dashboardFooterMobile/DasgboardFooterMobile";
import DashboardContainer from "../global/dashboardContainer/DashboardContainer";
import FooterMobile from "../global/dashboardFooterMobile/FooterMobile";

const DashboardLayout = ({ children, FooterHide, spacialMenu }) => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <DashboardContainer FooterHide={FooterHide} >
        {children}
      </DashboardContainer>
      {FooterHide !== true && spacialMenu && <DashboardFooterMobile />}
      {FooterHide !== true && !spacialMenu && <FooterMobile />}
    </div>
  );
};

export default DashboardLayout;
