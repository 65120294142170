import React from "react";
import ModuleSection from "./ModuleSection";

function Modules() {
  window.scrollTo(0, 0);

  return (
    <div>
      <div className="Discover">
        <div>
          <span className="shopping"></span>
        </div>
        <div>
          <span>Discover more functionality</span>
          <span>in our store</span>
        </div>
      </div>
      <div className="Moduletitle">
        <p>YOUR SUBSCRIPTIONS</p>
      </div>
      <div className="ModuleSections">
        <ModuleSection />
        <ModuleSection />
        <ModuleSection />
      </div>
    </div> 
  );
}

export default Modules;
