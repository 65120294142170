import React from "react";
import "./Error.css";

function AlertComponent() {
  return (
    <div className="Alert">
      <p>Something Went Wrong</p>
      <button
        onClick={() => {
          window.location.reload(false);
        }}
      >
        Refresh Page
      </button>
    </div>
  );
}

export default AlertComponent;
