import { useEffect } from "react";
import { UseAppContext } from "../context/Context";
import { useKeycloak } from "@react-keycloak/web";
import { USER_INFO } from "../context/actions/User";

const UseAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const { user, modifyUser } = UseAppContext();
  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login().then((data) => {});
      } else {
        keycloak.loadUserInfo().then((userInfo) => {
          localStorage.setItem("token", keycloak.token);
          modifyUser({ type: USER_INFO, payload: userInfo });
        });
      }
    } else return;
  }, [user.isLogin, initialized]);

  return { isLogin: user.isLogin, userAuthLoading: !initialized };
};

export default UseAuth;
