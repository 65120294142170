import { Route } from "react-router-dom";
import Loading from "./components/common/loading/Loading";
import DashboardLayout from "./components/layout/DashboardLayout";
import UseAuth from "./customHooks/UseAuth";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isLogin, userAuthLoading } = UseAuth();

// old spinnder loader, we dont need it after the splash screen
/*  if (userAuthLoading) {
    return <Loading w={150} h={150} />;
  } */ 

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <DashboardLayout spacialMenu={false}>
            <Component {...props} />
          </DashboardLayout>
        ) : null
      }
    />
  );
};

export default PrivateRoute;
