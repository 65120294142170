import React from "react";

function ModuleSection() {
  return (
    <div className="Modulesection">
      <div></div>
      <div>
        <span>Module Name</span>
        <span>Logimo</span>
        <span>Subscribtion since 12.02.2020</span>
      </div>
      <div>
        <span className="arrowRight"></span>
      </div>
    </div>
  );
}

export default ModuleSection;
